@import '../Filter.module';
@import '../../../../../styles/animation-variables';

.container {
  position: relative;

  display: flex;

  width: 100%;
  max-width: 170px;
  margin-right: 8px;
}

.button {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;


  width: 100%;
  padding: 10px 16px;
  border: none;

  cursor: pointer;
}

.arrows {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 12px;
  height: 18px;
}

.arrowUp {
  position: absolute;
  top: 0;
  color: var(--text-20);
}

.arrowDown {
  position: absolute;
  bottom: 0;
  color: var(--text-20);
}
