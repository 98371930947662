@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

.error {
  @include font-style-default;
  padding: 20px 0;
  color: var(--system-error);
  background: var(--shape-10);

  text-align: center;
}

.loading {
  padding: 15px 0;
  background: var(--shape-10);
}

.list {
  outline: none;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 89px;
}

.evenRow {
  background: var(--shape-10);
}

.showMoreButton {
  @include font-style-tiny;
  display: flex;
  flex-direction: row;

  padding: 10px 16px;

  color: var(--system-error);
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--system-error);

  cursor: pointer;
  transition: $defaultTransitionDuration $smoothTransitionTiming background-color;

  &:focus {
    outline: none;
  }
}

.nothingFound {
  @include font-style-subtitle;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 91px;

  color: var(--text-40);
  background: var(--shape-10);
}
