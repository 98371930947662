@import 'src/design-system/styles/typography.mixins';

.datePicker {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 200px;
  margin-right: 8px;

  & > :global(.react-datepicker-wrapper),
  :global(.react-datepicker__input-container) {
    width: 100%;
    height: 100%;
  }
}

.calendar {
  @include font-style-tiny;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 10px 35px 10px 16px;
  border: none;
  border-radius: 4px;

  background: transparent;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
    background: var(--shape-50);
  }
}

.icon {
  position: absolute;
  right: 16px;
  pointer-events: none;
  color: var(--text-100);
}
