@import 'src/design-system/styles/typography.mixins';
@import '../../../../../styles/animation-variables';

.search {
  @include font-style-tiny;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  max-width: 408px;
  width: 100%;
  height: 41px;
  border: none;
}

.background {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: var(--shape-10);
  border-radius: 4px;
  z-index: 10;
  transition: $defaultTransitionDuration $smoothTransitionTiming background-color;
}

.searchIcon {
  position: absolute;
  left: 15px;

  z-index: 20;
  pointer-events: none;

  color: var(--text-10);
}

.searchInput {
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;
  padding-left: 43px;
  padding-right: 20px;
  border: 1px solid var(--text-30);
  border-radius: 4px;

  background: transparent;

  outline: none;
  z-index: 20;

  &:hover,
  &:focus {
    border-color: var(--text-link);
  }
}

.reset {
  padding: 0;
  width: 40px;
  height: 100%;

  background: transparent;
  border: none;

  outline: none;
  z-index: 20;

  &:hover,
  &:focus {
    & svg {
      fill: var(--primary-60);
    }
  }
}

.resetIcon {
  color: var(--text-40);

  & svg {
    transition: $defaultTransitionDuration $smoothTransitionTiming fill;
  }
}
