@import 'src/design-system/styles/typography.mixins';

.tables {
  display: flex;
  flex-direction: column;
}

.nothingSelected {
  @include font-style-subtitle;
  margin-top: 15px;
  text-align: center;
  color: var(--text-40);
}

.shadowBox {
  margin-bottom: 18px;
  box-shadow: var(--shadow-1);
  background: var(--shape-20);
  border-radius: 8px;
  overflow: hidden;
}
