@import 'src/design-system/styles/typography.mixins';
@import '../../../../../../styles/animation-variables';

.match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 91px;
  padding: 0 36px;
}

.dateTime {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 30%;
}

.time {
  @include font-style-tiny;
  color: var(--text-20);
}

.date {
  @include font-style-extra-small;
  color: var(--text-20);
}

.live {
  @include font-style-extra-small;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 29px;
  height: 18px;
  margin-right: 89px;

  color: var(--text-10);
  background: var(--system-error);
  border-radius: 2px;

  cursor: pointer;
}

.teams {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  height: 54px;
  width: 30%;
}

.team {
  @include font-style-tiny;
  display: flex;
  justify-content: flex-start;
  color: var(--text-20);
}

.statistic {
  margin-left: 10px;
  cursor: pointer;
}

.scores {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 54px;
  width: 20%;

  color: var(--text-20);
}

.statistics {
  width: 10%;
  text-align: center;

  &:hover svg {
    fill: var(--system-error);
  }
}

.statisticsIcon {
  color: var(--system-error);

  & > svg {
    transition: $defaultTransitionDuration $smoothTransitionTiming fill;
  }
}

.bet {
  width: 10%;
  color: var(--text-20);
  text-align: center;
}
