@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 82px;
  width: 100%;
  padding: 31px 36px;
}

.sport {
  @include font-style-small;
  width: 30%;
  color: var(--text-20);
}

.tournament {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 30%;

  cursor: pointer;
}

.button {
  @include font-style-tiny;
  display: flex;

  padding: 0;

  color: var(--text-10);
  background: transparent;
  border: none;
  outline: none;
  transition: $defaultTransitionDuration $smoothTransitionTiming color;

  &:hover,
  &:focus {
    color: var(--text-30);

    & .arrowUp {
      transform: translateY(-2px);
    }

    & .arrowDown {
      transform: translateY(2px);
    }
  }
}

.arrows {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 12px;
  height: 18px;
  margin-left: 12px;
}

.arrowUp {
  position: absolute;
  top: 0;
  transition: $defaultTransitionDuration $smoothTransitionTiming transform;
  color: var(--text-20);
}

.arrowDown {
  position: absolute;
  bottom: 0;
  transition: $defaultTransitionDuration $smoothTransitionTiming transform;
  color: var(--text-20);
}

.score {
  @include font-style-tiny;
  width: 20%;
}

.statistics {
  @include font-style-tiny;
  width: 10%;
  text-align: center;
}

.bet {
  @include font-style-tiny;
  width: 10%;
  text-align: center;
}
