@import 'src/design-system/styles/typography.mixins';

.results {
  padding: 18px 0 6px;
}

.shadowBox {
  margin-bottom: 18px;
  box-shadow: var(--shadow-1);
  background: var(--shape-10);
  border-radius: 8px;
}

.error {
  @include font-style-h4;
  padding: 30px;
  text-align: center;
  color: var(--system-error);
}
